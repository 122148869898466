import React from 'react';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import { Avatar, Button, List, Typography } from 'antd';
import dispatherService from '../../services/dispatherService';

const { Title } = Typography;

function TeamConfirm() {
    const { teams, currentTeamId, integrationParams, config } = useSelector((state: AppState) => ({
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
        integrationParams: state.general.integrationParams,
        config: state.general.config,
    }));

    const team = teams.find(team => team.id === currentTeamId)!;
    const teamMembers = team.members
        .filter(member => member.unti_id !== Number(integrationParams.unti_id))
        .map(member => {
            return member;
        });

    const getNextTeam = () => {
        let filteredTeams = teams.filter(team => {
            return !team.isFilled && team.id !== currentTeamId;
        });

        return filteredTeams.length > 0 ? filteredTeams[0] : false;
    };

    const confirm = () => {
        dispatherService.setStage('selectAccelerator');
    };

    const cancel = () => {
        let nextTeam = getNextTeam();

        if (nextTeam) {
            dispatherService.setCurrentTeamFilled();
            dispatherService.setCurrentTeamId(nextTeam.id);
            dispatherService.setStage('confirmTeam');
        } else {
            dispatherService.setCurrentTeamFilled();
            dispatherService.setStage('resultFail');
        }
    };

    return (
        <div className="teams-confirm">
            <Title className="teams-confirm__title" level={3}>
                {config.welcomeText.replace('$TEAMNAME', team.name)}
            </Title>
            <Title className="teams-confirm__title" level={5}>
                {config.welcomeText2}
            </Title>
            <List
                className="teams-confirm__list"
                header={false}
                footer={false}
                dataSource={teamMembers}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar style={{ backgroundColor: item.color }}>
                                    {item.last_name[0]}
                                    {item.name[0]}
                                </Avatar>
                            }
                            title={item.last_name + ' ' + item.name}
                        />
                    </List.Item>
                )}
            />
            <div className="teams-confirm__buttons">
                <Button className="teams-confirm__confirm" type="primary" onClick={confirm}>
                    Да, это моя команда
                </Button>
                <Button className="teams-confirm__cancel" type="dashed" danger onClick={cancel}>
                    Нет, это не моя команда
                </Button>
            </div>
        </div>
    );
}

export default TeamConfirm;
