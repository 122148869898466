import React from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';

function MembersList() {
    const { teams, currentTeamId, integrationParams } = useSelector((state: AppState) => ({
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
        integrationParams: state.general.integrationParams,
    }));

    const currentTeam = teams.find(team => team.id === currentTeamId)!;

    const selectMember = (unti_id: number) => {
        dispatherService.selectMember(unti_id);
    };

    const membersCheckbox = currentTeam.members.map(member => {
        if (member.unti_id === Number(integrationParams.unti_id)) return null;

        return (
            <div className="members-list__item" key={member.unti_id}>
                <Checkbox onChange={() => selectMember(member.unti_id)} checked={member.isSelected}>
                    {member.last_name + ' ' + member.name}
                </Checkbox>
            </div>
        );
    });

    return <div className="members-list">{membersCheckbox}</div>;
}

export default MembersList;
