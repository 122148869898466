import React from 'react';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';

function ResultFail() {
    const { teams } = useSelector((state: AppState) => ({
        teams: state.general.teams,
    }));

    let title;
    if (teams.length === 0 || teams.every(team => team.isFilled)) {
        title = 'Извините, мы не смогли найти вашу команду';
    } else {
        title = 'Извините, что-то пошло не так';
    }

    return (
        <div className="result">
            <Result
                status="warning"
                title={title}
                subTitle="Нажмите на&nbsp;кнопку &laquo;помощь&raquo; ниже"
            />
        </div>
    );
}

export default ResultFail;
