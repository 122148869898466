import React, { useEffect } from "react";
import IntegrationParams from "./components/IntegrationParams";
import IntegrationParamsType from "./types/IntegrationParams";
import AppState from "./types/AppState";
import { connect, useSelector } from "react-redux";
import Teams from "./components/Teams/Teams";
import { Spin } from "antd";
import Stage from "./types/Stage";
import TeamConfirm from "./components/Teams/TeamConfirm";
import Tasks from "./components/Tasks/Tasks";
import Members from "./components/Members/Members";
import Assessment from "./components/Assessment/Assessment";
import ResultSuccess from "./components/Result/ResultSuccess";
import ResultFail from "./components/Result/ResultFail";
import TasksSure from "./components/TasksSure/TasksSure";
import Accelerators from "./components/Accelerator/Accelerators";
import integrationService from "./services/integrationService";

interface IApp {
  integrationParams: IntegrationParamsType;
  fetchTeams: boolean;
  fetchTasks: boolean;
  stage: Stage;
  fetchTeamsError: any;
}

function App(props: IApp) {
  const { integrationParams } = useSelector((state: AppState) => ({
    integrationParams: state.general.integrationParams,
  }));

  useEffect(() => {
    (async () => {
      if (integrationParams.silentMode === "off")
        await integrationService.sendStart(integrationParams);
    })();
  }, [integrationParams]);

  useEffect(() => {
    (async () => {
      let stagesArray = [
        "selectTeam",
        "confirmTeam",
        "selectAccelerator",
        "selectTasks",
        "selectTasksSure",
        "selectTeamMembers",
        "assessment",
      ];

      let indexStage = stagesArray.findIndex((item) => item === props.stage);
      if (indexStage > 0)
        if (integrationParams.silentMode === "off")
          await integrationService.reportProgress(
            integrationParams,
            Math.floor((indexStage / stagesArray.length) * 100)
          );
    })();
  }, [integrationParams, props.stage]);

  let content;
  if (props.fetchTeams) {
    content = <Spin className="app__loading" tip="Загрузка данных..." />;
  } else if (props.fetchTeamsError === null) {
    content = (
      <>
        {props.stage === "selectTeam" && <Teams />}
        {props.stage === "confirmTeam" && <TeamConfirm />}
        {props.stage === "selectAccelerator" && <Accelerators />}
        {props.stage === "selectTasks" && <Tasks />}
        {props.stage === "selectTasksSure" && <TasksSure />}
        {props.stage === "selectTeamMembers" && <Members />}
        {props.stage === "assessment" && <Assessment />}
        {props.stage === "resultSuccess" && <ResultSuccess />}
        {props.stage === "resultFail" && <ResultFail />}
      </>
    );
  } else {
    content = <ResultFail />;
  }

  return (
    <div className="app">
      <div className="app-main">{content}</div>
    </div>
  );
}

const mapStateToProps = (store: AppState) => {
  return {
    stage: store.general.stage,
    integrationParams: store.general.integrationParams,
    fetchTeams: store.general.fetchTeams,
    fetchTeamsError: store.general.fetchTeamsError,
  };
};
export default connect(mapStateToProps)(IntegrationParams(App));
