import React from 'react';
import { Avatar, Rate, Select } from 'antd';
import dispatherService from '../../services/dispatherService';
import Task from '../../types/Task';
import TeamMember from '../../types/TeamMember';
import { CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

interface IAssessmentListProps {
    tasks: Task[];
    members: TeamMember[];
    setFillingTaskId: (taskId: number) => void;
    setIsModalOpened: (isModalOpened: boolean) => void;
    handleModalOk: (taskId: number, memberUntiId: number, assessment: number) => void;
    removeAssessment: (taskId: number, memberUntiId: number) => void;
}

function AssessmentList(props: IAssessmentListProps) {
    const assessmentsList = props.tasks.map(task => {
        const taskOptions = props.members.map(member => {
            let isDisabled = !!task.assessments.find(
                assessment => assessment.unti_id === member.unti_id
            );
            return (
                <Option value={member.unti_id} disabled={isDisabled} key={Math.random()}>
                    {member.last_name} {member.name}
                </Option>
            );
        });

        const assessments = task.assessments.map((assessment, index) => {
            let member = props.members.find(member => member.unti_id === assessment.unti_id)!;

            return (
                <div className="assessment-task__assessment" key={'assessment-' + task.id + index}>
                    <Avatar
                        className="assessment-task__assessment-avatar"
                        style={{ backgroundColor: member.color }}
                        size="large"
                    >
                        {member.last_name[0]}
                        {member.name[0]}
                        <div
                            className="assessment-task__assessment-remove"
                            onClick={() => props.removeAssessment(task.id, assessment.unti_id)}
                        >
                            <CloseOutlined />
                        </div>
                    </Avatar>
                    <Rate
                        className="assessment-task__assessment-rate"
                        value={assessment.value}
                        onChange={value => props.handleModalOk(task.id, member.unti_id, value)}
                    />
                </div>
            );
        });

        return (
            <div className="assessment-task" key={task.id}>
                <div className="assessment-task__header">
                    <p className="assessment-task__title">{task.value}</p>
                </div>
                <div className="assessment-task__body">
                    <div className="assessment-task__add">
                        <Select
                            className="assessment-task__add-select"
                            placeholder="Выберите участника для добавления"
                            value={task.selectedMemberUntiId}
                            onChange={value => {
                                dispatherService.selectMemberUntiId(task.id, value);
                                props.setFillingTaskId(task.id);
                                props.setIsModalOpened(true);
                            }}
                        >
                            {taskOptions}
                        </Select>
                    </div>
                    <div className="assessment-task__assessments">{assessments}</div>
                </div>
            </div>
        );
    });

    return <div className="assessment-tasks">{assessmentsList}</div>;
}

export default AssessmentList;
