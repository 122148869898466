import React, { useEffect } from 'react';
import TeamsList from './TeamsList';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';

const { Title } = Typography;

function Teams() {
    const { teams } = useSelector((state: AppState) => ({
        teams: state.general.teams,
    }));

    useEffect(() => {
        if (teams.length === 1) {
            selectTeam(teams[0].id);
        }
    }, [teams]);

    const selectTeam = (id: number) => {
        dispatherService.setCurrentTeamId(id);
        dispatherService.setStage('confirmTeam');
    };

    return (
        <div className="teams">
            <Title className="teams__title" level={3}>
                Выберите команду для оценки:
            </Title>
            <TeamsList selectTeam={selectTeam} teams={teams.filter(team => !team.isFilled)} />
        </div>
    );
}

export default Teams;
