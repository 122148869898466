import React from 'react';
import { Button, Table } from 'antd';
import Team from '../../types/Team';

interface ITeamsList {
    selectTeam: (id: number) => void;
    teams: Team[];
}

function TeamsList(props: ITeamsList) {
    const dataSource = props.teams.map(team => {
        return {
            key: team.id,
            name: team.name,
            membersCount: team.members.length,
            select: team.id,
        };
    });

    const columns = [
        {
            title: 'Название команды',
            dataIndex: 'name',
            key: 'name',
            render: (text: React.ReactNode) => <b>{text}</b>,
        },
        {
            title: 'Кол-во участников',
            dataIndex: 'membersCount',
            key: 'membersCount',
            render: (text: React.ReactNode) => <span>Кол-во участников: {text}</span>,
        },
        {
            title: '',
            dataIndex: 'select',
            key: 'select',
            render: (text: string, row: any) => (
                <Button type="primary" onClick={() => props.selectTeam(row.select)}>
                    Выбрать команду
                </Button>
            ),
        },
    ];

    return (
        <Table
            className="teams__table"
            dataSource={dataSource}
            columns={columns}
            size="small"
            pagination={false}
            showHeader={false}
        />
    );
}

export default TeamsList;
