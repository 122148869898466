import React, { useState } from 'react';
import { Button, Modal, Rate } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import Task from '../../types/Task';

interface IAssessmentModalProps {
    isModalOpened: boolean;
    handleModalOk: (taskId: number, memberUntiId: number, assessment: number) => void;
    handleModalCancel: () => void;
    taskId?: number;
}
function AssessmentModal(props: IAssessmentModalProps) {
    const { teams, currentTeamId } = useSelector((state: AppState) => ({
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
    }));

    const currentTeam = teams.find(team => team.id === currentTeamId)!;
    let tasks: Task[] = [];
    currentTeam.task_blocks.forEach(taskBlock => {
        taskBlock.tasks.forEach(task => {
            tasks.push(task);
        });
    });
    const task = tasks.find(task => task.id === props.taskId)!;
    const member = currentTeam.members.find(
        member => member.unti_id === task.selectedMemberUntiId
    )!;

    const [assessment, setAssessment] = useState<number>();

    const desc = [
        'Едва ли проявил(а) себя',
        'Слабо проявил(а) себя',
        'Средне проявил(а) себя',
        'Хорошо проявил(а) себя',
        'Отлично проявил(а) себя',
    ];

    return (
        <Modal
            className="assessment-modal"
            title="Оцените полезность участника"
            visible={props.isModalOpened}
            footer={false}
            onCancel={props.handleModalCancel}
        >
            <div className="assessment-modal__content">
                <p className="assessment-modal__member">
                    {member.last_name} {member.name}:
                </p>
                <p className="assessment-modal__task">{task.value}</p>
                <div className="assessment-modal__rate">
                    {assessment && (
                        <p className="ant-rate-text assessment-modal__rate-title">
                            {desc[assessment - 1]}
                        </p>
                    )}
                    <Rate
                        className="assessment-modal__rate-rate"
                        tooltips={desc}
                        onChange={value => setAssessment(value)}
                        value={assessment}
                    />
                </div>
                <div className="assessment-modal__buttons">
                    <Button
                        className="assessment-modal__button"
                        type="primary"
                        onClick={() =>
                            props.handleModalOk(task.id, member.unti_id, assessment || 0)
                        }
                        disabled={!assessment}
                    >
                        Ок
                    </Button>
                    <Button
                        className="assessment-modal__button"
                        type="default"
                        onClick={props.handleModalCancel}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default AssessmentModal;
