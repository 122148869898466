import React from "react";
import Accelerator from "../../types/Accelerator";
import { Radio } from "antd";

interface IAcceleratorsListProps {
  selectAccelerator: (id: number) => void;
  accelerators: Accelerator[];
  selectedAcceleratorId: number | null;
}
function AcceleratorsList(props: IAcceleratorsListProps) {
  const acceleratorsList = props.accelerators.map((accelerator) => {
    return (
      <Radio className="accelerators__list-radio" value={accelerator.id} key={accelerator.id}>
        {accelerator.value}
      </Radio>
    );
  });

  return (
    <div className="accelerators__list">
      <Radio.Group
        onChange={(e) => props.selectAccelerator(e.target.value)}
        value={props.selectedAcceleratorId}
      >
        {acceleratorsList}
      </Radio.Group>
    </div>
  );
}

export default AcceleratorsList;
