const actions = {
    general: {
        FETCH_TEAMS: 'FETCH_TEAMS',
        FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
        FETCH_TEAMS_ERROR: 'FETCH_TEAMS_ERROR',
        FETCH_TASKS: 'FETCH_TASKS',
        FETCH_TASKS_SUCCESS: 'FETCH_TASKS_SUCCESS',
        FETCH_TASKS_ERROR: 'FETCH_TASKS_ERROR',
        SET_CURRENT_TEAM_ID: 'SET_CURRENT_TEAM_ID',
        SET_CURRENT_TEAM_FILLED: 'SET_CURRENT_TEAM_FILLED',
        SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
        SET_STAGE: 'SET_STAGE',
        SELECT_TASK: 'SELECT_TASK',
        SELECT_SURE_TASK: 'SELECT_SURE_TASK',
        SET_TASKS_FOR_CURRENT_TEAM: 'SET_TASKS_FOR_CURRENT_TEAM',
        SELECT_MEMBER: 'SELECT_MEMBER',
        SET_CURRENT_TEAM_MEMBER_ASSESSMENT: 'SET_CURRENT_TEAM_MEMBER_ASSESSMENT',
        REMOVE_CURRENT_TEAM_MEMBER_ASSESSMENT: 'REMOVE_CURRENT_TEAM_MEMBER_ASSESSMENT',
        SET_CURRENT_TEAM_MEMBER_IS_NOT_ENGAGE: 'SET_CURRENT_TEAM_MEMBER_IS_NOT_ENGAGE',
        SET_CURRENT_TEAM_MEMBER_INTERACTION_ASSESSMENT:
            'SET_CURRENT_TEAM_MEMBER_INTERACTION_ASSESSMENT',
        SELECT_MEMBER_UNTI_ID: 'SELECT_MEMBER_UNTI_ID',
        SELECT_ACCELERATOR: 'SELECT_ACCELERATOR',
    },
};

export default actions;
