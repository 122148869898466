import storeService from './storeService';
import actions from '../store/actions/types';
import IntegrationParams from '../types/IntegrationParams';
import Stage from '../types/Stage';

class DispatherService {
    setParamsByElement = (element: HTMLElement | null): void => {
        if (element) {
            const token = element.getAttribute('data-user-token'),
                unti_id = element.getAttribute('data-unti-id'),
                activity_uuid = element.getAttribute('data-activity-uuid'),
                silentMode = element.getAttribute('data-silent-mode');

            storeService.getStore().dispatch({
                type: actions.general.SET_INTEGRATION_PARAMS,
                payload: {
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                    silentMode: silentMode,
                } as IntegrationParams,
            });
        }
    };

    setCurrentTeamId = (id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CURRENT_TEAM_ID,
            payload: id,
        });
    };

    setCurrentTeamFilled = (): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CURRENT_TEAM_FILLED,
        });
    };

    setStage = (stage: Stage): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_STAGE,
            payload: stage,
        });

        const webbot = document.querySelector('#webbot');

        if (webbot)
            webbot.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
    };

    selectTask = (id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_TASK,
            payload: id,
        });
    };

    selectSureTask = (id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_SURE_TASK,
            payload: id,
        });
    };

    selectMember = (unti_id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_MEMBER,
            payload: unti_id,
        });
    };

    setTasksForCurrentTeam = (): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_TASKS_FOR_CURRENT_TEAM,
        });
    };

    setCurrentTeamMemberAssessment = (
        taskId: number,
        memberUntiId: number,
        assessment: number
    ): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CURRENT_TEAM_MEMBER_ASSESSMENT,
            payload: {
                taskId: taskId,
                memberUntiId: memberUntiId,
                assessment: assessment,
            },
        });
    };

    removeCurrentTeamMemberAssessment = (taskId: number, memberUntiId: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_CURRENT_TEAM_MEMBER_ASSESSMENT,
            payload: {
                taskId: taskId,
                memberUntiId: memberUntiId,
            },
        });
    };

    setCurrentTeamMemberIsNotEngage = (id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CURRENT_TEAM_MEMBER_IS_NOT_ENGAGE,
            payload: { id: id },
        });
    };

    setCurrentTeamMemberInteractionAssessment = (assessment: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_MEMBER_UNTI_ID,
            payload: assessment,
        });
    };

    selectMemberUntiId = (taskId: number, unti_id?: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_MEMBER_UNTI_ID,
            payload: { taskId: taskId, unti_id: unti_id },
        });
    };

    selectAccelerator = (id: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SELECT_ACCELERATOR,
            payload: { id: id },
        });
    };
}

const dispatherService = new DispatherService();
export default dispatherService;
