import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import dispatherService from '../../services/dispatherService';
import AcceleratorsList from './AcceleratorsList';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';

const { Title } = Typography;

function Accelerators() {
    const { accelerators, teams, currentTeamId, config } = useSelector((state: AppState) => ({
        accelerators: state.general.accelerators,
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
        config: state.general.config,
    }));

    const team = teams.find(team => team.id === currentTeamId)!;
    const selectedAcceleratorId = team.selectedAcceleratorId;

    useEffect(() => {
        if (team.task_blocks.every(taskBlock => taskBlock.accelerator_id === 0))
            dispatherService.setStage('selectTasks');
    }, [team.task_blocks]);

    const selectAccelerator = (id: number) => {
        dispatherService.selectAccelerator(id);
    };

    const isSelected = () => {
        return selectedAcceleratorId;
    };

    const confirm = () => {
        dispatherService.setStage('selectTasks');
    };

    return (
        <div className="accelerators">
            <Title className="accelerators__title" level={3}>
                {config.chooseTrackText}
            </Title>
            <AcceleratorsList
                selectAccelerator={selectAccelerator}
                accelerators={accelerators}
                selectedAcceleratorId={selectedAcceleratorId}
            />
            <Button
                type="primary"
                className="accelerators__button"
                disabled={!isSelected()}
                onClick={confirm}
            >
                Подтвердить выбор
            </Button>
        </div>
    );
}

export default Accelerators;
