import axios from 'axios';
import storeService from './storeService';
import actions from '../store/actions/types';
import Team from '../types/Team';
import Accelerator from '../types/Accelerator';
import Task from '../types/Task';
import dispatherService from './dispatherService';

const url = process.env.REACT_APP_API!;

class DataService {
    loadTeams() {
        const currentState = storeService.getStore().getState().general;
        const { unti_id, activity_uuid } = currentState.integrationParams;

        storeService.getStore().dispatch({
            type: actions.general.FETCH_TEAMS,
        });
        axios
            .get(url + `complete_data/${activity_uuid}/${unti_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                const teams = response.data.teams.map((team: Team) => {
                    team.isFilled = false;

                    team.members.map(member => {
                        member.isSelected = false;
                        member.color =
                            '#' +
                            Math.random()
                                .toString(16)
                                .substr(-6);

                        return member;
                    });

                    let members = team.members.map(member => {
                        // @ts-ignore
                        let { first_name, second_name } = member;

                        return {
                            ...member,
                            name: first_name,
                            last_name: second_name,
                        };
                    });
                    team.members = members.filter(member => Number(unti_id) !== member.unti_id);

                    let selfUser = members.find(member => Number(unti_id) === member.unti_id)!;
                    selfUser.isSelected = true;
                    team.members.push(selfUser);

                    team.task_blocks = team.task_blocks.map(taskBlock => {
                        taskBlock.tasks = taskBlock.tasks.map(task => {
                            task.isSelected = false;
                            task.isSelectedSure = false;
                            task.assessments = [];
                            task.selectedMemberUntiId = undefined;

                            return task;
                        });

                        return taskBlock;
                    });

                    return team;
                });

                const accelerators = response.data.accelerators
                    .map((accelerator: Accelerator) => {
                        accelerator.id = accelerator.accelerator_id;

                        return accelerator;
                    })
                    .sort((a: Accelerator, b: Accelerator) => {
                        let aValue = a.value.toLowerCase(),
                            bValue = b.value.toLowerCase();

                        return aValue < bValue ? -1 : 1;
                    });

                const config = response.data.config;

                storeService.getStore().dispatch({
                    type: actions.general.FETCH_TEAMS_SUCCESS,
                    payload: {
                        teams: teams,
                        accelerators: accelerators,
                        config: config,
                    },
                });

                if (teams.length === 0) dispatherService.setStage('resultFail');
            })
            .catch(err => {
                console.log({ err });
                storeService.getStore().dispatch({
                    type: actions.general.FETCH_TEAMS_ERROR,
                    payload: err,
                });
            });
    }

    getResult() {
        const currentState = storeService.getStore().getState().general;
        const { teams } = currentState;

        return {
            teams: teams.map((team: Team) => {
                let tasks: Task[] = [];
                team.task_blocks.forEach(taskBlock => {
                    taskBlock.tasks.forEach(task => {
                        tasks.push(task);
                    });
                });

                return {
                    id: team.id,
                    name: team.name,
                    members: team.members.map(member => {
                        return {
                            unti_id: member.unti_id,
                            name: member.name,
                            last_name: member.last_name,
                            isSelected: member.isSelected,
                        };
                    }),
                    tasks: tasks
                        .filter(task => task.isSelected && task.isSelectedSure)
                        .map(task => {
                            return {
                                id: task.id,
                                value: task.value,
                                assessments: task.assessments,
                            };
                        }),
                    selectedAcceleratorId: team.selectedAcceleratorId,
                };
            }),
        };
    }

    saveResult() {
        const {
            unti_id,
            activity_uuid,
        } = storeService.getStore().getState().general.integrationParams;

        return fetch(url + `user_result`, {
            body: JSON.stringify({
                unti_id: Number(unti_id),
                activity_uuid: activity_uuid,
                data: this.getResult(),
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

const dataService = new DataService();
export default dataService;
