import React from 'react';
import { Button, Typography } from 'antd';
import MembersList from './MembersList';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';

const { Title } = Typography;

function Members() {
    const { teams, currentTeamId, integrationParams, config } = useSelector((state: AppState) => ({
        teams: state.general.teams,
        currentTeamId: state.general.currentTeamId,
        integrationParams: state.general.integrationParams,
        config: state.general.config,
    }));

    const currentTeam = teams.find(team => team.id === currentTeamId)!;

    const isSelected = () => {
        return currentTeam.members.some(member => {
            return member.isSelected && member.unti_id !== Number(integrationParams.unti_id);
        });
    };

    const confirm = () => {
        dispatherService.setStage('assessment');
    };

    return (
        <div className="members">
            <Title className="members__title" level={3}>
                {config.teammatesText}
            </Title>
            <MembersList />
            <Button
                type="primary"
                className="members__button"
                disabled={!isSelected()}
                onClick={confirm}
            >
                Подтвердить выбор
            </Button>
        </div>
    );
}

export default Members;
