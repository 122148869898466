import React from 'react';
import { Button, Typography } from 'antd';
import TasksSureList from './TasksSureList';
import dispatherService from '../../services/dispatherService';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import Task from '../../types/Task';

const { Title } = Typography;

function TasksSure() {
    const { teams, currentTeamId, config } = useSelector((state: AppState) => ({
        currentTeamId: state.general.currentTeamId,
        teams: state.general.teams,
        config: state.general.config,
    }));

    let tasks: Task[] = [];
    teams
        .find(team => team.id === currentTeamId)!
        .task_blocks.forEach(taskBlock => {
            taskBlock.tasks.forEach(task => {
                tasks.push(task);
            });
        });

    const isSelected = () => {
        return tasks.some(task => {
            return task.isSelectedSure;
        });
    };

    const confirm = () => {
        dispatherService.setTasksForCurrentTeam();
        dispatherService.setStage('selectTeamMembers');
    };

    return (
        <div className="tasks">
            <Title className="tasks__title" level={3}>
                {config.finalTaskSelectText}
            </Title>
            <TasksSureList />
            <Button
                type="primary"
                className="tasks__button"
                disabled={!isSelected()}
                onClick={confirm}
            >
                Подтвердить выбор
            </Button>
        </div>
    );
}

export default TasksSure;
