import React from 'react';
import { Checkbox, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';

const { Panel } = Collapse;

function TasksSureList() {
    const { teams, currentTeamId } = useSelector((state: AppState) => ({
        currentTeamId: state.general.currentTeamId,
        teams: state.general.teams,
    }));

    const taskBlocks = teams.find(team => team.id === currentTeamId)!.task_blocks;
    const tasksList = taskBlocks.map((taskBlock, index) => {
        const tasks = taskBlock.tasks
            .filter(task => task.isSelected)
            .map(task => {
                return (
                    <div className="tasks-list__item" key={task.id}>
                        <Checkbox
                            onChange={() => selectTask(task.id)}
                            checked={task.isSelectedSure}
                        >
                            {task.value}
                        </Checkbox>
                    </div>
                );
            });

        if (tasks.length > 0) {
            return (
                <Panel header={taskBlock.value} key={index}>
                    {tasks}
                </Panel>
            );
        } else {
            return null;
        }
    });

    const selectTask = (id: number) => {
        dispatherService.selectSureTask(id);
    };

    return (
        <div className="tasks-list">
            <Collapse
                bordered={true}
                defaultActiveKey={taskBlocks.map((taskBlock, index) => {
                    return index;
                })}
            >
                {tasksList}
            </Collapse>
        </div>
    );
}

export default TasksSureList;
