import React from 'react';
import { Checkbox, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import dispatherService from '../../services/dispatherService';

const { Panel } = Collapse;

function TasksList() {
    const { teams, currentTeamId } = useSelector((state: AppState) => ({
        currentTeamId: state.general.currentTeamId,
        teams: state.general.teams,
    }));

    const team = teams.find(team => team.id === currentTeamId)!;
    const selectedAcceleratorId = team.selectedAcceleratorId;

    const selectTask = (id: number) => {
        dispatherService.selectTask(id);
    };

    const taskBlocks = teams.find(team => team.id === currentTeamId)!.task_blocks;
    const tasksList = taskBlocks.map((taskBlock, index) => {
        const tasks = taskBlock.tasks.map(task => {
            return (
                <div className="tasks-list__item" key={task.id}>
                    <Checkbox onChange={() => selectTask(task.id)} checked={task.isSelected}>
                        {task.value}
                    </Checkbox>
                </div>
            );
        });

        return taskBlock.accelerator_id === null ||
            taskBlock.accelerator_id === selectedAcceleratorId ||
            taskBlock.accelerator_id === 0 ? (
            <Panel header={taskBlock.value} key={index}>
                {tasks}
            </Panel>
        ) : null;
    });

    return (
        <div className="tasks-list">
            <Collapse
                bordered={true}
                defaultActiveKey={taskBlocks.map((taskBlock, index) => {
                    return index;
                })}
            >
                {tasksList}
            </Collapse>
        </div>
    );
}

export default TasksList;
