import React from 'react';
import { Result } from 'antd';

function ResultSuccess() {
    return (
        <div className="result">
            <Result
                status="success"
                title="Ваши данные успешно сохранены."
                subTitle="После расчета ответов всех участников команды они будут доступны в командном дэшборде."
            />
        </div>
    );
}

export default ResultSuccess;
