import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';
import Task from '../../types/Task';
import Team from '../../types/Team';

const initialState: GeneralState = {
    fetchTeams: false,
    fetchTeamsError: null,
    accelerators: [],
    teams: [],
    config: {
        welcomeText:
            'Это список участников данной команды «$TEAMNAME», он мог незначительно измениться за время Архипелага.',
        welcomeText2:
            'Если большая часть из них вам знакомы, выберите «Да, это моя команда». Если не знакомы, выберите «Нет, я из другой команды»',
        chooseTrackText: 'Выберите акселератор своей команды на Архипелаге',
        taskSelectText:
            'Выберите те типы задач, выполнением которых были заняты участники вашей команды во время Архипелага',
        finalTaskSelectText:
            'Из перечисленных типов задач выберите те, выполнение которых вы могли бы оценить',
        teammatesText:
            'Выберите участников команды, с которыми вы взаимодействовали или коммуницировали в рамках проекта',
        tasksAssessmentText:
            'Для каждого типа задач выберите участвоваших в реализации задачи (кого-то из коллег или себя). Оцените вклад по пятибальной системе.',
        taskAssessmentText2:
            'К каждой задаче можно добавить более одного участника. Выбрать каждого участника можно более одного раза',
    },
    currentTeamId: 0,
    stage: 'selectTeam',
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
        silentMode: 'off',
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.general.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_TEAMS: {
            let currentState = Object.assign({}, state);
            currentState.fetchTeams = true;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_TEAMS_SUCCESS: {
            let currentState = Object.assign({}, state);
            currentState.teams = action.payload.teams.map((item: Team, index: number) => {
                return {
                    ...item,
                    id: index,
                };
            });
            currentState.accelerators = action.payload.accelerators;
            if (Object.keys(action.payload.config).length > 0)
                currentState.config = action.payload.config;
            currentState.fetchTeams = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_TEAMS_ERROR: {
            let currentState = Object.assign({}, state);
            currentState.fetchTeamsError = action.payload;
            currentState.fetchTeams = false;

            return updateObject(state, currentState);
        }
        case actions.general.SET_STAGE: {
            let currentState = Object.assign({}, state);
            currentState.stage = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_CURRENT_TEAM_ID: {
            let currentState = Object.assign({}, state);
            currentState.currentTeamId = action.payload;
            console.log(action.payload);

            return updateObject(state, currentState);
        }
        case actions.general.SET_CURRENT_TEAM_FILLED: {
            let currentState = Object.assign({}, state);
            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            currentTeam.isFilled = true;

            return updateObject(state, currentState);
        }
        case actions.general.SELECT_TASK: {
            let currentState = Object.assign({}, state);
            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            let tasks: Task[] = [];
            currentTeam.task_blocks.forEach(taskBlock => {
                taskBlock.tasks.forEach(task => {
                    tasks.push(task);
                });
            });
            let task = tasks.find(task => task.id === action.payload);

            if (task) task.isSelected = !task.isSelected;

            return updateObject(state, currentState);
        }
        case actions.general.SELECT_SURE_TASK: {
            let currentState = Object.assign({}, state);
            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;
            let tasks: Task[] = [];
            currentTeam.task_blocks.forEach(taskBlock => {
                taskBlock.tasks.forEach(task => {
                    tasks.push(task);
                });
            });
            let task = tasks.find(task => task.id === action.payload);

            if (task) task.isSelectedSure = !task.isSelectedSure;

            return updateObject(state, currentState);
        }
        case actions.general.SELECT_MEMBER: {
            let currentState = Object.assign({}, state);
            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            );
            let currentMember = currentTeam?.members.find(
                member => member.unti_id === action.payload
            );

            if (currentTeam && currentMember) currentMember.isSelected = !currentMember.isSelected;

            return updateObject(state, currentState);
        }
        case actions.general.SELECT_MEMBER_UNTI_ID: {
            let { taskId, unti_id } = action.payload;

            let currentState = Object.assign({}, state);
            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            let tasks: Task[] = [];
            currentTeam.task_blocks.forEach(taskBlock => {
                taskBlock.tasks.forEach(task => {
                    tasks.push(task);
                });
            });
            let task = tasks.find(task => task.id === taskId)!;

            task.selectedMemberUntiId = unti_id;

            return updateObject(state, currentState);
        }
        case actions.general.SET_CURRENT_TEAM_MEMBER_ASSESSMENT: {
            let { taskId, memberUntiId, assessment } = action.payload;

            let currentState = Object.assign({}, state);

            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            let tasks: Task[] = [];
            currentTeam.task_blocks.forEach(taskBlock => {
                taskBlock.tasks.forEach(task => {
                    tasks.push(task);
                });
            });

            let currentTask = tasks.find(task => task.id === taskId)!;

            let currentAssessment = currentTask.assessments.find(
                assesment => assesment.unti_id === memberUntiId
            );
            if (currentAssessment) {
                currentAssessment.value = assessment;
            } else {
                currentTask.assessments.push({
                    unti_id: memberUntiId,
                    value: assessment,
                });
            }

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_CURRENT_TEAM_MEMBER_ASSESSMENT: {
            let { taskId, memberUntiId } = action.payload;

            let currentState = Object.assign({}, state);

            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            currentTeam.task_blocks.forEach(taskBlock => {
                taskBlock.tasks.forEach(task => {
                    task.assessments.forEach((assessment, index) => {
                        if (task.id === taskId && assessment.unti_id === memberUntiId)
                            task.assessments.splice(index, 1);
                    });
                });
            });

            return updateObject(state, currentState);
        }
        case actions.general.SELECT_ACCELERATOR: {
            let { id } = action.payload;

            let currentState = Object.assign({}, state);

            let currentTeam = currentState.teams.find(
                team => team.id === currentState.currentTeamId
            )!;

            currentTeam.selectedAcceleratorId = id;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};
